import React from 'react'
import Helmet from 'react-helmet'

const defaults = {
  title: `Code and design | Johannes Ekman`,
  author: 'Johannes Ekman',
  type: `website`,
  keywords: 'programming, design, developer, web, ux, ui',
  description: `Hi, I'm Johannes! I make designs and code. Find out more!`,
  url: `johannesekman.com`,
  image: `https://images.ctfassets.net/l676jgezidjs/2WI8vM7GLmgsm84qE00oW4/42b4a4de08f3095f914a7693b0adc5f6/favicon-5043f7916d0948f3f66d9237b8facb53-4ca11.png?fm=png&fl=png8`
}

const MetaTags = ({ title, author, type, keywords, description, url, image }) => (
  <Helmet>
    <title>{title ? `${title} | Johannes Ekman` : defaults.title}</title>
    <meta name='author' content={author || defaults.author} />
    <meta name='keywords' content={keywords || defaults.keywords} />
    <meta name='description' content={description || defaults.description} />
    <meta itemprop='image' content={image || defaults.image} />

    <meta property='og:url' content={url || defaults.url} />
    <meta property='og:type' content={type || defaults.type} />
    <meta property='og:title' content={title || defaults.title} />
    <meta property='og:description' content={description || defaults.description} />
    <meta property='og:image' content={image || defaults.image} />
    <meta property='og:site_name' content={url || defaults.url} />

    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:title' content={title || defaults.title} />
    <meta name='twitter:description' content={description || defaults.description} />
    <meta name='twitter:image' content={image || defaults.image} />
  </Helmet>
)

export default MetaTags
