import React from 'react'

export default ({ fullheight = '', height = 'auto', children, style = {}, ...rest }) => (
  <div style={{ marginTop: fullheight ? 0 : height ? 0 : 65, ...style }} {...rest}>
    <div className='level'>
      <div
        className='level-item'
        style={{
          minHeight: fullheight ? (style.padding ? `calc(100vh - ${style.padding}px * 2)` : '100vh') : height
        }}
      >
        {children}
      </div>
    </div>
  </div>
)
