import React from 'react'

export default () => (
  <footer className='footer'>
    <div className='content has-text-centered'>
      <p>
        <strong>
          <span role='img' title='waving emoji-hand' aria-label='waving emoji-hand'>
            👋
          </span>{' '}
          Hi, I'm Johannes!
        </strong>{' '}
        I write about JavaScript, Networking and Node.js
      </p>
    </div>
  </footer>
)
