import React, { Component } from 'react'
import styled from 'styled-components'
import { Spring, animated } from 'react-spring'
import { Link } from 'gatsby'
import Section from './Section'

const Nav = styled.nav`
  width: 100%;
  position: fixed;
  height: 0;
  z-index: 1;

  ul {
    margin: 0 !important;
  }

  li a {
    font-size: 3.25rem;
    letter-spacing: -2.5px;
    font-weight: 600;
    color: #111;

    &:hover {
      color: #00000075;
    }
  }

  .inverted {
    li a {
      color: white;
      &:hover {
        color: #ffffff50;
      }
    }
  }

  & + div,
  & ~ footer {
    transition-delay: .4s;
    transition-timing-function: ease;
    transition-duration: .3s;

    filter: ${(props) => (props.active ? 'blur(20px)' : 'none')};
  }
`

const Menu = styled.div`
  button {
    /* font-variant: all-small-caps; */
    cursor: pointer;
    z-index: 100;
    color: #111;
    border: 0;
    box-shadow: 1px 2px 0px #00000026;
    background: ${(props) => (props.active ? '#ececec' : '#f1f1f1')};
    padding: 7.5px 10px;
    font-size: 22px;
    margin: 15px;
    font-weight: 700;
    border-radius: 4px;
    float: right;
    position: absolute;
    right: 0;
    letter-spacing: 1px;

    &:focus {
      outline: none;
    }
  }
`

const NavContents = ({ active, activeItem, ...props }) => (
  <Spring
    native
    to={{
      borderRadius: active ? '0' : '100%',
      transform: active ? 'translateY(0) translateX(0)' : 'translateY(-100%) translateX(100%)'
    }}
  >
    {(styles) => (
      <animated.div
        style={{ background: '#ffffff90', height: '100vh', width: '100%', ...styles }}
        {...props}
        className='content gpu-enabled'
      >
        <Section fullheight style={{ color: 'white' }}>
          <ul>
            <li className={`${activeItem === 'home' ? 'active' : ''}`}>
              <Link to='/'>Home</Link>
            </li>
            <li className={`${activeItem === 'blog' ? 'active' : ''}`}>
              <Link to='/blog'>Blog</Link>
            </li>
          </ul>
        </Section>
      </animated.div>
    )}
  </Spring>
)

class MobileNav extends Component {
  state = {
    toggle: false
  }

  triggerNav = () => {
    this.setState({
      toggle: !this.state.toggle
    })
  }

  render () {
    const { withContrast, activeItem } = this.props
    return (
      <Nav className={withContrast ? 'inverted' : null} active={this.state.toggle}>
        <Menu active={this.state.toggle} onClick={this.triggerNav}>
          <Spring native to={{ transform: this.state.toggle ? 'scale(1.2)' : 'scale(1)' }}>
            {(styles) => <animated.button style={styles}>MENU</animated.button>}
          </Spring>
        </Menu>
        <NavContents active={this.state.toggle} activeItem={activeItem} />
      </Nav>
    )
  }
}

export default MobileNav
