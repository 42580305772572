import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Footer from './Footer'
import Navigation from './Navigation'
import MetaTags from './MetaTags'

import 'bulma'
import '../static/global.scss'

const Main = styled.main`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

export const favicon = `//images.ctfassets.net/l676jgezidjs/2WI8vM7GLmgsm84qE00oW4/42b4a4de08f3095f914a7693b0adc5f6/favicon-5043f7916d0948f3f66d9237b8facb53-4ca11.png?fm=png&fl=png8`

export default ({ children, footerless, activeItem, withContrast, ...rest }) => (
  <Main>
    <MetaTags />
    <Helmet>
      <link rel='icon' type='image/png' href={favicon} />
      <link rel='apple-touch-icon-precomposed' sizes='144x144' href={`${favicon}&w=144&h=144`} />
      <link rel='apple-touch-icon-precomposed' sizes='114x114' href={`${favicon}&w=114&h=114`} />
      <link rel='apple-touch-icon-precomposed' sizes='72x72' href={`${favicon}&w=72&h=72`} />
      <link rel='apple-touch-icon-precomposed' href={`${favicon}&w=57&h=57`} />
      <link rel='preconnect' href='//images.ctfassets.net' />
    </Helmet>
    <Navigation activeItem={activeItem} withContrast={withContrast} />
    <div style={{ flex: 1 }}>{children}</div>
    {!footerless && <Footer />}
  </Main>
)
