import React from 'react'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import docker from '../static/icons/docker.svg'
import git from '../static/icons/git.svg'
import javascript from '../static/icons/javascript.svg'
import nodejs from '../static/icons/nodejs.svg'
import react from '../static/icons/react.svg'
import vue from '../static/icons/vue.svg'
import mongodb from '../static/icons/mongodb.svg'

export const DesignerAndCoder = () => (
  <React.Fragment>
    <Link to='/blog/category/design'>UX/UI designer</Link>
    <span> and </span>
    <a href='https://github.com/EKMN' target='_blank' rel='noopener noreferrer'>
      coder
    </a>
  </React.Fragment>
)

export const Emoji = () => (
  <span role='img' title='waving emoji-hand' aria-label='waving emoji-hand' className='emoji'>
    👋
  </span>
)

const defaultStacks = [
  { img: nodejs, description: 'Node.js' },
  { img: react, description: 'React.js' },
  { img: vue, description: 'Vue.js' },
  { img: javascript, description: 'JavaScript' },
  { img: docker, description: 'Docker' },
  { img: mongodb, description: 'MongoDB' },
  { img: git, description: 'Git' }
]

const DevStack = ({ stacks = defaultStacks }) => (
  <div className='level'>
    <div className='level-item'>
      <div className='columns'>
        {stacks.map((stack, i) => (
          <div className='column stack-transition' key={i}>
            <figure className='image is-64x64'>
              <img src={stack.img} alt={stack.description} />
            </figure>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default () => (
  <React.Fragment>
    <Helmet>
      <link rel='dns-prefetch' href='//github.com' />
    </Helmet>
    <Layout activeItem='home' footerless>
      <Section fullheight>
        <div style={{ padding: 20, textAlign: 'center' }} className='boldened-links'>
          <h1 className='title is-1 is-spaced'>Hello there</h1>
          <h2 className='subtitle is-4 w400' style={{ maxWidth: 340, lineHeight: 1.4, marginTop: '0.5em' }}>
            I'm Johannes, a <DesignerAndCoder />. Learn more about my process on my <Link to='/blog'>blog</Link>.
          </h2>
        </div>
      </Section>
      <Section height='30vh' style={{ background: '#f7f7f7' }} className='scaled-on-mobile'>
        <div style={{ padding: '50px 15px' }}>
          <DevStack />
        </div>
      </Section>
      <Section fullheight>
        <a href='mailto:johannes@ekman.co' style={{ margin: 15 }}>
          <button className='btn styled'>Contact Me</button>
        </a>
      </Section>
    </Layout>
  </React.Fragment>
)
